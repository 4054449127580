import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../GeoLocation/GeoLocation.scss";
import worldmap from "../../Assets/worldmap.png";

function GeoLocation() {
  return (
    <>
      <Container fluid className="map">
        <div class="map-container">
          <img src={worldmap} />
          <div class="point venezuela tippy" title="Venezuela"></div>
          <div class="point brasil tippy" title="Brasil"></div>
          <div class="point argentina tippy" title="Argentina"></div>
          <div class="point colombia tippy" title="Colombia"></div>
          <div class="point panama tippy" title="Panamá"></div>
          <div class="point mexico tippy" title="Mexico"></div>
          <div class="point usa tippy" title="Estados Unidos"></div>
          <div class="point arabia tippy" title="Arabia Saudi"></div>
          <div class="point turquia tippy" title="Turquía"></div>
          <div class="point rusia tippy" title="Rusia"></div>
          <div class="point china tippy" title="China"></div>
          <div class="point japon tippy" title="Japon"></div>
          <div class="point australia tippy" title="Australia"></div>
        </div>

        <div className="coverage">
          <p>EUROPE</p>
          <p>APAC</p>
          <p>LATAM</p>
          <p>CIS</p>
          <p>US & Canada</p>
        </div>
      </Container>
    </>
  );
}

export default GeoLocation;
