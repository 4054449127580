import React, { useState } from "react";
import LandingPage from "./LandingPage/LandingPage";
import Services from "./Services/Services";
import { Col, Container, Row } from "react-bootstrap";
import "./Component.scss";
import icon from "../Assets/Favicon.png";
import btnarr from "../Assets/btn-arrow.png";
import Footer from "./Footer/Footer";
import Aboutus from "./Aboutus/Aboutus";
import Industries from "./Industries/Industries";
import Geolocation from "../Components/GeoLocation/GeoLocation.js";
import Blogs from "./Blogs/Blogs";
import { HashRouter as Router, Routes, Route, Link } from "react-router-dom";
import BlogComponent from "./BlogComponent/BlogComponent";
import useWindowSize from "./files/useWindowSize";

import globe from "../Assets/world.svg";
import gear from "../Assets/gear.png";
import blogs from "../Assets/blogs.png";
import home from "../Assets/home.png";
import services from "../Assets/services.png";
function Components() {
  const size = useWindowSize();
  const [page, setPage] = useState("home");
  return (
    <div>
      <Container fluid>
        <Row className="navbar">
          <Col className="logoCol" md={4} sm={6}>
            <img onClick={() => setPage("home")} src={icon}></img>
          </Col>
          {size.width > 768 && (
            <Col className="links" md={8}>
              <Link
                to="/"
                onClick={() => {
                  setPage("home");
                  window.scroll(0, 0);
                }}
                className={page === "home" ? "activenav" : ""}
              >
                <a
                  href="/"
                  onClick={() => {
                    setPage("home");
                    window.scroll(0, 0);
                  }}
                  className={page === "home" ? "activenav" : ""}
                >
                  About Us
                </a>
              </Link>
              <Link
                to="/services"
                onClick={() => {
                  setPage("services");
                  window.scroll(0, 0);
                }}
                className={page === "services" ? "activenav" : ""}
              >
                <a
                  href="#services"
                  onClick={() => {
                    setPage("services");
                    window.scroll(0, 0);
                  }}
                  className={page === "services" ? "activenav" : ""}
                >
                  Services
                </a>
              </Link>
              <Link
                to="/blogs"
                onClick={() => {
                  setPage("blogs");
                  window.scroll(0, 0);
                }}
                className={page === "blogs" ? "activenav" : ""}
              >
                <a
                  href="#blogs"
                  onClick={() => {
                    setPage("blogs");
                    window.scroll(0, 0);
                  }}
                  className={page === "blogs" ? "activenav" : ""}
                >
                  Blogs
                </a>
              </Link>
              <Link
                to="/industries"
                onClick={() => {
                  setPage("industries");
                  window.scroll(0, 0);
                }}
                className={page === "industries" ? "activenav" : ""}
              >
                <a
                  href="#industries"
                  onClick={() => {
                    setPage("industries");
                    window.scroll(0, 0);
                  }}
                  className={page === "industries" ? "activenav" : ""}
                >
                  Industries
                </a>
              </Link>
              <Link
                to="/geolocation"
                onClick={() => {
                  setPage("geolocation");
                  window.scroll(0, 0);
                }}
                className={page === "geolocation" ? "activenav" : ""}
              >
                <a
                  href="#about"
                  onClick={() => {
                    setPage("geolocation");
                    window.scroll(0, 0);
                  }}
                  className={page === "geolocation" ? "activenav" : ""}
                >
                  GeoLocation
                </a>
              </Link>
              <Link to="/connect">
                <a className="buttondiv">
                  Let's Connect{" "}
                  <span className="icon">
                    <img src={btnarr}></img>
                  </span>
                </a>
              </Link>
            </Col>
          )}

          {size.width < 768 && <Col sm={6}></Col>}
        </Row>
      </Container>

      <Routes>
        <Route path="/" element={<Aboutus />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/industries" element={<Industries />} />
        <Route path="/blogContent" element={<BlogComponent />} />
        <Route path="/geolocation" element={<Geolocation />} />
      </Routes>
      <Footer data={setPage} page={page} />
      {size.width < 768 && (
        <div className="mobile_footer">
          <Link
            to="/"
            onClick={() => {
              setPage("home");
              window.scroll(0, 0);
            }}
            className={page === "home" ? "activenav" : ""}
          >
            <img src={home}></img>
          </Link>
          <Link
            to="/services"
            onClick={() => {
              setPage("services");
              window.scroll(0, 0);
            }}
            className={page === "services" ? "activenav" : ""}
          >
            <img src={services}></img>
          </Link>
          <Link
            to="/blogs"
            onClick={() => {
              setPage("blogs");
              window.scroll(0, 0);
            }}
            className={page === "blogs" ? "activenav" : ""}
          >
            <img src={blogs}></img>
          </Link>
          <Link
            to="/industries"
            onClick={() => {
              setPage("industries");
              window.scroll(0, 0);
            }}
            className={page === "industries" ? "activenav" : ""}
          >
            <img src={gear}></img>
          </Link>
          <Link
            to="/geolocation"
            onClick={() => {
              setPage("geolocation");
              window.scroll(0, 0);
            }}
            className={page === "geolocation" ? "activenav" : ""}
          >
            <img src={globe}></img>
          </Link>
        </div>
      )}
    </div>
  );
}
export default Components;
